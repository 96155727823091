<template>
  <div class="tips-premium">
    <template v-if="showDesglose">
      <GMakingMoneyTips @show="show" />
    </template>
    <template v-else>
      <div class="tips-premium__making">
        <MakingMoney
          @show="
            showButton();
            messageAnalyticsSeeDetails();
          "
        />
      </div>
      <div class="tips-premium__individual-bets" v-if="selectedScenario.bets.length > 0"><IndividualBets /></div>
      <div class="tips-premium__bets" :class="{ 'tips-premium__bets--mobile': betsMobileFlag }">
        <template v-if="selectedScenario.bets.length > 0">
          <div v-for="bet of selectedScenario.bets" :key="bet.bet_id">
            <div v-if="!betsMobileFlag" class="tips-premium__bets__bet">
              <ParlayGame :is-mobile="parlayGameMobileFlag" :bet="bet" is-tip />
            </div>
            <div v-else class="tips-premium__bets__bet">
              <TipsGameMobile :bet="bet" />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="tips-premium__no-doubles">
            No existen en Caliente las suficientes apuestas de este torneo. Esperaremos a que Caliente actualice sus
            apuestas para poder proporcionarte la mejor sugerencia posible.
          </div>
        </template>
      </div>
      <div class="tips-premium__info" v-if="selectedScenario.bets.length > 0">
        <TipsSelectedInfo v-model="simulatorAmount" :risk-percentage="riskPercentage" :final-amount="finalAmount" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { tipsDetails } from '@/utils/analytics';
import { getBetsPerTeam } from '@/utils/api/tips';
import { getTipsPercentage } from '@/utils/calculations';

export default {
  name: 'ParlayPremium',
  components: {
    ParlayGame: () => import('@/components/Sections/GolTipster-v2/General/ParlayGame'),
    TipsGameMobile: () => import('@/components/Sections/GolTipster-v2/Tips/TipsGameMobile'),
    TipsSelectedInfo: () => import('@/components/Sections/GolTipster-v2/Tips/TipsSelectedInfo'),
    MakingMoney: () => import('@/components/Sections/GolTipster-v2/General/MakingMoney'),
    IndividualBets: () => import('@/components/Sections/GolTipster-v2/General/IndividualBets'),
    GMakingMoneyTips: () => import('@/components/Sections/GolTipster-v2/General/GMakingMoneyTips'),
  },
  data() {
    return {
      parlayGameMobileFlag: false,
      showDesglose: false,
      availableScenarios: [],
      selectedParlayIndex: 0,
      selectedScenario: {
        bets: [],
      },
      betsMobileFlag: false,
      simulatorAmount: 200,
    };
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapState('betsGeneral', ['refreshIntervalCounter']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    isDisabled: function() {
      let vm = this;
      return index => {
        return vm.disabledBets[index];
      };
    },
    riskPercentage() {
      let riskPercentage = 0;
      if (this.selectedScenario.bets.length) {
        riskPercentage = getTipsPercentage(
          this.selectedScenario.bets.map(bet => {
            return { momio: bet.odd, bet: bet.investment };
          }),
        );
      }
      return Math.round(riskPercentage);
    },
    finalAmount() {
      let finalAmount = 0;
      if (this.selectedScenario.bets.length) {
        finalAmount = this.selectedScenario.bets.reduce((accumulator, bet) => {
          if (bet.isVisible) {
            if (bet.odd > 0) {
              // eslint-disable-next-line no-param-reassign
              accumulator += (bet.investment * bet.odd) / 100 + bet.investment;
            } else {
              // eslint-disable-next-line no-param-reassign
              accumulator += (bet.investment * 100) / Math.abs(bet.odd) + bet.investment;
            }
          }
          return accumulator;
        }, 0);
      }
      return Number(finalAmount.toFixed(2));
    },
  },
  watch: {
    displayWidth: {
      immediate: true,
      handler(newValue) {
        this.betsMobileFlag = newValue < 542;
      },
    },
    selectedScenario: {
      deep: true,
      handler() {
        this.calcBetsInvestment();
      },
    },
    simulatorAmount() {
      this.calcBetsInvestment();
    },
    refreshIntervalCounter() {
      this.fillInitialData();
    },
  },
  methods: {
    ...mapActions('betsGeneral', ['getParlayFree']),
    messageAnalyticsSeeDetails() {
      tipsDetails({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
      });
    },
    showButton() {
      this.showDesglose = true;
    },
    show() {
      this.showDesglose = !this.showDesglose;
    },
    calcBetsInvestment() {
      this.selectedScenario.bets.forEach(bet => {
        bet.investment = Number((this.simulatorAmount * (bet.percentage_maximized / 100)).toFixed(2));
      });
    },
    async fillParlayInfo() {
      try {
        const betsByTournament = await getBetsPerTeam({ teamId: 0 });
        betsByTournament.forEach(tournament => {
          tournament.scenarios.forEach(scenario => {
            scenario.bets.forEach(bet => {
              bet.home_team_acronym = bet.home_team.acronym;
              bet.home_team_logo = bet.home_team.logo;
              bet.away_team_acronym = bet.away_team.acronym;
              bet.away_team_logo = bet.away_team.logo;
              bet.investment = 0;
              bet.isVisible = true;
            });
          });
        });
        // liga mx
        let betTournament = betsByTournament.find(tournament => tournament.tournament_id === 1);
        if (!betTournament || betTournament.scenarios.length === 0) {
          // culaquier otro que no sea mixto
          betTournament = betsByTournament
            .filter(tournament => tournament.tournament_id !== 0 && tournament.tournament_id !== 1)
            .find(tournament => tournament.scenarios.length);
        }
        if (!betTournament || betTournament.scenarios.length === 0) {
          // mixto
          betTournament = betsByTournament.find(tournament => tournament.tournament_id === 0);
        }
        if (betTournament) {
          this.availableScenarios = betTournament.scenarios;
          this.setSelectedScenario();
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    setSelectedScenario() {
      if (this.availableScenarios.length) {
        this.selectedScenario = this.availableScenarios[0];
      }
    },
    onInput($event, index) {
      this.disabledBets[index] = $event;
    },
    async fillInitialData() {
      try {
        await this.getParlayFree();
        await this.fillParlayInfo();
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  created() {
    this.fillInitialData();
  },
};
</script>

<style scoped lang="scss">
.tips-premium {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  padding-bottom: 2em;
  &__no-doubles {
    width: 100%;
    font-size: 1.25em;
    height: 23.5em;
    padding: 5.5em 4em 2em;
    font-family: Roboto-Regular, sans-serif;

    @media screen and (max-width: 580px) {
      padding: 3.5em 2em;
      height: fit-content;
    }
  }

  &__making {
    width: 100%;
    margin-top: 34px;
    margin-bottom: 14px;
  }
  &__individual-bets {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 14px;
  }

  &__bets {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 1em;
    padding: 0.5em 0.375em;
    width: fit-content;

    &__bet {
      padding: 0 0.375em;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0.5em 0;
    }

    &--mobile {
      flex-direction: column;
      flex-wrap: nowrap;
      width: 100%;
      align-items: center;

      & > div {
        width: 100%;
        margin-top: 0.5em;
      }

      & .tips-premium__bets {
        &__bet {
          width: 100%;
          margin: 0;
        }
      }
    }

    @media screen and (max-width: 1022px) {
      width: 724px;
    }

    @media screen and (max-width: 750px) {
      width: 488px;
    }

    @media screen and (max-width: 502px) {
      width: 100%;
    }
  }

  &__info {
    width: 100%;
    max-width: 60.25em;
    padding: 0 0.6em;
  }
}
</style>
